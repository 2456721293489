<template>
  <div class="department-card">
    <department-card-navigation :id="this.id" />
    <div class="department-card-wrapper">
      <department-card-info></department-card-info>

      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import DepartmentCardNavigation from "components/department-card/DepartmentCardNavigation";
import DepartmentCardInfo from "components/department-card/DepartmentCardInfo";

import { mapActions } from "vuex";
import * as names from "store/names";

export default {
  name: "DepartmentCardComponent",
  props: ["id"],
  components: { DepartmentCardNavigation, DepartmentCardInfo },
  data: () => ({}),
  created() {
    this.saveDepId(this.id);
  },

  methods: {
    ...mapActions({
      saveDepId: names.ACTION_SAVE_DEPARTMENT_ID_CARD,
    }),
  },
};
</script>

<style scoped lang="scss">
@import "~scss/helpers/helpers";
.department-card {
  font-size: 14px;
  line-height: 17px;
  max-width: 930px;
  margin: 40px auto 60px;

  .department-card-wrapper {
    border: 10px solid #e3ecfc;
    font-family: $sanspro;
  }
}
</style>
